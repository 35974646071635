import React from 'react'

import { TabTitle } from '../../../utils/generalFunction'

const ForbiddenPage = () => {
  TabTitle("Không có quyền truy cập")

  return (
    <div>ForbiddenPage</div>
  )
}

export default ForbiddenPage